export const locales = {
  EN: 'en',
  KISW: 'kisw',
  FR: 'fr',
};

export default function translate() {
  if (!document.querySelector('[data-current-lang]')) return;

  const publicLang = window.localStorage.getItem('publicLang');

  if (!publicLang) {
    window.localStorage.setItem('publicLang', locales.EN);
    const newURL = new URL(window.location.href);
    newURL.searchParams.set('lang', locales.EN);
    window.location.href = newURL;
    return;
  }

  document.querySelector('[data-current-lang]').textContent = publicLang;

  listenToClickLocale(locales.EN);
  listenToClickLocale(locales.KISW);
  listenToClickLocale(locales.FR);

  const urlLangExists = new URLSearchParams(window.location.search).get('lang');

  if (!urlLangExists) {
    const newURL = new URL(window.location.href);
    newURL.searchParams.set('lang', publicLang);
    window.location.href = newURL;
  }
}

function listenToClickLocale(local) {
  const publicLang = window.localStorage.getItem('publicLang');

  document.querySelector(`[data-lang="${local}"]`).addEventListener('click', (ev) => {
    ev.preventDefault();

    if (publicLang !== local) {
      window.localStorage.setItem('publicLang', local);
      const newURL = new URL(window.location.href);
      newURL.searchParams.set('lang', local);
      window.location.href = newURL;
    }
  });
}